<template>
  <Navigation @scroll-to="scrollToSection" />
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import LogRocket from 'logrocket'
LogRocket.init('advais/advais')

export default {
  name: 'App',
  components: {
    Navigation
  },
  mounted () {
    document.title = 'advais — De founders para founders'
  },
  methods: {
    scrollToSection (refName) {
      this.$nextTick(() => {
        const element = this.$refs[refName]
        if (element) {
          const top = element.offsetTop
          window.scrollTo({
            top: top,
            behavior: 'smooth'
          })
        }
      })
    }
  }
}
</script>
