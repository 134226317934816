<template>
  <div id="nav" class="flex-cols" :class="{ 'scrolled-nav': scrolledNav, 'nav-pages': currentRoute !== '/', 'nav-home': currentRoute === '/' }">
    <div class="left align-left">
      <router-link to="/">
        advais
      </router-link>
    </div>
    <div class="desktop-nav right align-right" v-show="!mobile">
      <a class="nav-link" @click.prevent="navigateToSection('pricing')">Pricing</a>
      <a class="nav-link" @click.prevent="navigateToSection('team')">Equipo</a>
    </div>
    <div class="desktop-nav right-only-mobile align-right" v-show="mobile">
      <div @click="toggleMobileNav" class="nav-link" :class="{ 'mobile-nav-active': mobileNav }">
        <a class="mobile-nav-open">&#8801;</a>
      </div>
    </div>
    <transition name="show-nav">
      <div class="mobile-nav" v-show="mobileNav">
        <div @click="toggleMobileNav">
          <div class="nav-link">
            <p class="mobile-nav-close">&#x2715;</p>
          </div>
          <a class="nav-link" @click.prevent="navigateToSection('pricing')">Pricing</a><!--mobile-->
          <a class="nav-link" @click.prevent="navigateToSection('team')">Equipo</a><!--mobile-->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data () {
    return {
      scrolledNav: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null
    }
  },
  created () {
    window.addEventListener('resize', this.checkScreen)
    this.checkScreen()
  },
  computed: {
    currentRoute () {
      return this.$route.path
    }
  },
  mounted () {
    window.addEventListener('scroll', this.updateScroll)
    this.currentRoute = this.$route.path
  },
  methods: {
    toggleMobileNav () {
      this.mobileNav = !this.mobileNav
    },
    updateScroll () {
      const scrollPosition = window.scrollY
      if (scrollPosition > 20) {
        this.scrolledNav = true
        return
      }
      this.scrolledNav = false
    },
    checkScreen () {
      this.windowWidth = window.innerWidth
      if (this.windowWidth <= 780) {
        this.mobile = true
        return
      }
      this.mobile = false
      this.mobileNav = false
    },
    scrollMeTo (refName) {
      this.$emit('scroll-to', refName)
    },
    navigateToSection (sectionId) {
      this.$router.push({ path: '/', hash: `#${sectionId}` })
    }
  }
}
</script>
