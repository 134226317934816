<template>
  <div class="footer">
    <div class="left align-left">
      <div>
        <p>© 2024 — advais. De founders, para founders.</p>
        <p>hello@advais.cl</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
