<template>
  <div class="app">
    <div class="content home">
      <section class="hero">
        <h1>
        Toma mejores decisiones para tu startup especialmente en contextos de incertidumbre.<br>
        <span>En un mundo saturado de cortesía corporativa, te ofrecemos franqueza de startup #nobullsh*t.</span>
        </h1>
        <button class="primary-button mt-2em" @click.prevent="navigateToSection('about')">Empezar</button>
      </section>
      <section ref="about" class="secondary-bg padding-150 about" id="about">
        <div>
          <div class="grid-layout-2a1 grid-2a1-md mt-2-2em">
            <div>
              <h3>Qué hacemos</h3>
              <p class="p-bigger">Acompañamos a founders a idear, testear y construir startups tecnológicas en etapa temprana. #companybuilding</p>
            </div>
            <div>
              <h3>Cómo lo hacemos</h3>
              <p class="p-bigger">Implementamos la metodología que más se adecúe a tu startup para planificar, ejecutar e iterar con acompañamiento continuo. #trylearnrepeat</p>
            </div>
          </div>
        </div>
        <button class="primary-button mt-2em go-to-pricing" @click.prevent="navigateToSection('pricing')">Ver pricing</button>
      </section>
      <section ref="pricing" class="padding-150 pricing" id="pricing">
        <h2>Precios</h2>
        <div class="grid-layout-3a1 grid-3a1-md mt-3-3em">
            <div class="pricing-card pa-1em">
                <p class="pricing-title mb-0-5em">Advais Board Plus</p>
                <p class="pricing-description">Para founders que necesiten estructura en el día a día y metas claras para construir su startup.</p>
                <p class="pricing-tag"><span class="tag small">advisory board</span></p>
                <p class="pricing-price mb-0-5em">$495.000 <span class="smaller">/ mes</span></p>
                <p class="smaller">En base a plan trimestral</p>
                <p class="pricing-list-title">Sesiones semanales estructuradas para resolver desafíos de ventas, propuesta de valor, estrategia de producto, etc.</p>
                <p class="pricing-list-title">Contenidos:</p>
                <ul class="pricing-list">
                  <li>1 sesión semanal de 45 minutos</li>
                  <li><strong>2 advisors en conjunto</strong></li>
                </ul>
                <p class="pricing-list-title">Tipos de board:</p>
                <ul class="pricing-list">
                    <li>Comercial + Propuesta de valor</li>
                    <li>Comercial + Estrategia de producto</li>
                    <li>Propuesta de valor + Estrategia de producto</li>
                </ul>
                <span class="pricing-button-spacer"></span>
                <a href="https://zcal.co/beacereceda/30min" target="_blank" class="primary-button mt-1em mb-2em">Agendar</a>
            </div>
            <div class="pricing-card pa-1em">
                <p class="pricing-title mb-0-5em">Fractional C-level</p>
                <p class="pricing-description">Para founders determinados/as en acelerar su avance con procesos potentes y mejora sustancial.</p>
                <p class="pricing-tag"><span class="tag small">fractional c-level</span></p>
                <p class="pricing-price mb-0-5em">$1.625.000 <span class="smaller">/ mes</span></p>
                <p class="pricing-list-title"><strong>40 horas</strong> mensuales dentro del marco del rol, en actividades de planificación estratégica del área, gestión de equipo, manejo de terceros, etc.</p>
                <p class="pricing-list-title">Tipos de C-level:</p>
                <ul class="pricing-list">
                    <li>Chief Executive Officer CEO</li>
                    <li>Chief Product Officer CPO</li>
                    <li>Chief Financial Officer CFO</li>
                    <li>Chief Strategy Officer CSO</li>
                    <li>Chief Design Officer CDO</li>
                </ul>
                <span class="pricing-button-spacer"></span>
                <a href="https://zcal.co/beacereceda/30min" target="_blank" class="primary-button mt-1em mb-2em">Agendar</a>
            </div>
            <div class="pricing-card pa-1em pink">
                <p class="pricing-title mb-0-5em">Discovery</p>
                <p class="pricing-description pink">Para founders que buscan clarificar su norte de la mano de expertos/as.</p>
                <p class="pricing-tag"><span class="tag smal pink">consulting</span></p>
                <p class="pricing-price mb-0-5em">$150.000 <span class="smaller"></span></p>
                <p class="pricing-list-title">Reunión de consultoría para orientación en necesidades, objetivos y desafíos de la startup o proyecto.</p>
                <p class="pricing-list-title">Contenidos:</p>
                <ul class="pricing-list">
                  <li>1 sesión</li>
                  <li>1,5 horas</li>
                  <li>Informe</li>
                  <li><strong>1 advisor</strong></li>
                </ul>
                <span class="pricing-button-spacer"></span>
                <a href="https://zcal.co/beacereceda/30min" target="_blank" class="primary-button pink mt-1em mb-2em">Agendar</a>
            </div>
        </div>
    </section>
    <section class="secondary-bg padding-150 team" id="team">
      <h2>Equipo</h2>
      <div class="team-section">
        <div class="team-member">
          <div class="team-avatar">bc</div>
          <div class="team-info">
            <div class="team-name">
              <span>Bea Cereceda</span>
              <div class="team-linkedin">
                <a href="https://www.linkedin.com/in/beatriz-cereceda/" target="_blank">Linkedin</a>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <g clip-path="url(#clip0_48_787)">
                <path d="M12.5 0.5C11.8086 0.5 11.25 1.05859 11.25 1.75C11.25 2.44141 11.8086 3 12.5 3H15.7305L7.86719 10.8672C7.37891 11.3555 7.37891 12.1484 7.86719 12.6367C8.35547 13.125 9.14844 13.125 9.63672 12.6367L17.5 4.76953V8C17.5 8.69141 18.0586 9.25 18.75 9.25C19.4414 9.25 20 8.69141 20 8V1.75C20 1.05859 19.4414 0.5 18.75 0.5H12.5ZM3.125 1.75C1.39844 1.75 0 3.14844 0 4.875V17.375C0 19.1016 1.39844 20.5 3.125 20.5H15.625C17.3516 20.5 18.75 19.1016 18.75 17.375V13C18.75 12.3086 18.1914 11.75 17.5 11.75C16.8086 11.75 16.25 12.3086 16.25 13V17.375C16.25 17.7188 15.9688 18 15.625 18H3.125C2.78125 18 2.5 17.7188 2.5 17.375V4.875C2.5 4.53125 2.78125 4.25 3.125 4.25H7.5C8.19141 4.25 8.75 3.69141 8.75 3C8.75 2.30859 8.19141 1.75 7.5 1.75H3.125Z" fill="#A6A6A6"/>
                </g>
                <defs>
                <clipPath id="clip0_48_787">
                <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
                </defs>
                </svg>
              </div>
            </div>
            <div class="team-tags">
              <div class="tag">founder</div>
              <div class="tag">ceo</div>
              <div class="tag">cfo</div>
              <div class="tag">fintech</div>
              <div class="tag">ventures</div>
              <div class="tag">mentorship</div>
              <div class="tag">business development</div>
            </div>
          </div>
        </div>
        <div class="team-member">
          <div class="team-avatar">ab</div>
          <div class="team-info">
            <div class="team-name">
              <span>Andrea Baccelliere</span>
              <div class="team-linkedin">
                <a href="https://www.linkedin.com/in/andreabaccelliere/" target="_blank">Linkedin</a>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <g clip-path="url(#clip0_48_787)">
                <path d="M12.5 0.5C11.8086 0.5 11.25 1.05859 11.25 1.75C11.25 2.44141 11.8086 3 12.5 3H15.7305L7.86719 10.8672C7.37891 11.3555 7.37891 12.1484 7.86719 12.6367C8.35547 13.125 9.14844 13.125 9.63672 12.6367L17.5 4.76953V8C17.5 8.69141 18.0586 9.25 18.75 9.25C19.4414 9.25 20 8.69141 20 8V1.75C20 1.05859 19.4414 0.5 18.75 0.5H12.5ZM3.125 1.75C1.39844 1.75 0 3.14844 0 4.875V17.375C0 19.1016 1.39844 20.5 3.125 20.5H15.625C17.3516 20.5 18.75 19.1016 18.75 17.375V13C18.75 12.3086 18.1914 11.75 17.5 11.75C16.8086 11.75 16.25 12.3086 16.25 13V17.375C16.25 17.7188 15.9688 18 15.625 18H3.125C2.78125 18 2.5 17.7188 2.5 17.375V4.875C2.5 4.53125 2.78125 4.25 3.125 4.25H7.5C8.19141 4.25 8.75 3.69141 8.75 3C8.75 2.30859 8.19141 1.75 7.5 1.75H3.125Z" fill="#A6A6A6"/>
                </g>
                <defs>
                <clipPath id="clip0_48_787">
                <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
                </defs>
                </svg>
              </div>
            </div>
            <div class="team-tags">
              <div class="tag">founder</div>
              <div class="tag">cdo</div>
              <div class="tag">cpo</div>
              <div class="tag">user experience</div>
              <div class="tag">service design</div>
              <div class="tag">product development</div>
              <div class="tag">fintech</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="padding-150 conversion">
      <h4><del>Construye hoy mismo tu mejor versión del mañana, únete al club de las 3am...</del> <span>Or not. Ya te dijimos, #nobullshit</span></h4>
      <div>
        <a class="primary-button mt-2em" href="https://zcal.co/beacereceda/30min" target="_blank">EMPEZAR</a>
      </div>
    </section>
    </div>
    <Footer />
    <div class="bg-home"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from './Footer.vue'

export default {
  name: 'App',
  components: {
    Footer
  },
  mounted () {
    document.title = 'advais — De founders para founders'
  },
  methods: {
    scrollMeTo (refName) {
      this.$emit('scroll-to', refName)
    },
    navigateToSection (sectionId) {
      this.$router.push({ path: '/', hash: `#${sectionId}` })
    }
  }
}
</script>
